.app-menu {
  background-color: #333;
  padding: 10px 0;
}

.app-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-menu li {
  margin: 0 15px;
}

.app-menu a, .app-menu button {
  color: white;
  text-decoration: none;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  display: inline-block;
}

.app-menu a:hover, .app-menu button:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .app-menu ul {
    flex-direction: column;
  }

  .app-menu li {
    margin: 5px 0;
  }
}
