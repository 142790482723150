.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.header {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
}

.title {
  font-size: 2rem;
  margin: 0;
}

.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.signInBox, .userInfo {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #4285F4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.googleLogo {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
}

.dashboard {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}

.dashboardContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboardItem {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .dashboard {
    padding: 0.5rem;
  }

  .dashboardContent {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .dashboardItem {
    flex-basis: calc(50% - 10px);
    min-width: unset;
  }
}

@media (min-width: 1025px) {
  .dashboardContent {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .dashboardItem {
    flex: 1;
    min-width: 200px;
  }
}
